import React, {Suspense} from 'react'
import { Auth } from 'aws-amplify';
// import PPLAdmin from '../src/PPLComponents/pplAdmin'
const PPLAdmin = React.lazy(()=> import('./../src/PPLComponents/pplAdmin'))
class App extends React.Component{
    constructor(props){
        super(props)
        this.state={
            userName:''
        }
        this.callAuth()
    }

    callAuth = ()=>{
        Auth.currentUserInfo()
        .then((data) => {
        let userName = data['username']
        this.setState({userName:userName})
        })
        .catch(err => console.log(err));
    }

    render(){
        return(
            <Suspense fallback={null}>
                {this.state.userName != '' &&
               
                <PPLAdmin userName={this.state.userName}/>}
            </Suspense>
        )
    }
}
export default App