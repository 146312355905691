// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
aws_app_analytics: process.env.REACT_APP_AWS_APP_ANALYTICS,
aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
aws_cognito_region:  process.env.REACT_APP_AWS_COGNITO_REGION,
aws_content_delivery: process.env.REACT_APP_AWS_CONTENT_DELIVERY,
aws_content_delivery_bucket: process.env.REACT_APP_AWS_CONTENT_DELIVERY_BUCKET,
aws_content_delivery_bucket_region: process.env.REACT_APP_AWS_CONTENT_DELIVERY_BUCKET_REGION,
aws_content_delivery_cloudfront: process.env.REACT_APP_AWS_CONTENT_DELIVERY_CLOUDFRONT,
aws_content_delivery_cloudfront_domain: process.env.REACT_APP_AWS_CONTENT_DELIVERY_CLOUDFRONT_DOMAIN,
aws_mandatory_sign_in: process.env.REACT_APP_AWS_MANDATORY_SIGN_IN,
aws_mobile_analytics_app_id: process.env.REACT_APP_AWS_MOBILE_ANALYTICS_APP_ID,
aws_mobile_analytics_app_region: process.env.REACT_APP_AWS_MOBILE_ANALYTICS_APP_REGION,
aws_project_id: process.env.REACT_APP_AWS_PROJECT_ID,
aws_project_name:  process.env.REACT_APP_AWS_PROJECT_NAME,
aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
aws_resource_name_prefix: process.env.REACT_APP_AWS_RESOURCE_NAME_PREFIX,
aws_sign_in_enabled: process.env.REACT_APP_AWS_SIGN_IN_ENABLED,
aws_user_pools: process.env.REACT_APP_AWS_USER_POOLS,
aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
}
export default awsmobile;